import Vue from "vue";
import VueRouter from "vue-router";
import AuthStore from "@/stores/auth-store";
import accountLayout from "@/account/account-layout.vue";
import contractLayout from "@/contract/contract-layout.vue";
import adminLayout from "@/admin/admin-layout.vue";
import visitorLayout from "@/visitor/visitor-layout.vue";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  routes: [
    //{ path: '/', redirect: '/admin/home' },
    {
      path: "/",

      component: require("@/account/views/homepage/homepage.vue").default,
    },
    {
      path: "/account",
      component: accountLayout,
      children: [
        {
          path: "login",
          component: require("@/account/views/login/login.vue").default,
        },
        {
          path: "register",
          component: require("@/account/views/register/register.vue").default,
        },
        {
          path: "forgot-password",
          component: require("@/account/views/manage/forgot-password.vue")
            .default,
        },
        {
          path: "reset-password",
          component: require("@/account/views/manage/reset-password.vue")
            .default,
        },
        {
          path: "confirm-email",
          component: require("@/account/views/manage/confirm-email.vue")
            .default,
        },
      ],
    },
    {
      path: "/contract",
      component: contractLayout,
      meta: { disableControlAuth: true },
      children: [
        {
          path: "member",
          component: require("@/contract/member-contract.vue").default,
        },
        {
          path: "account-create-information",
          component: require("@/contract/account-create-information-contract.vue")
            .default,
        },
        {
          path: "cookie",
          component: require("@/contract/cookie-contract.vue").default,
        },
        {
          path: "data",
          component: require("@/contract/data-contract.vue").default,
        },
        {
          path: "communication",
          component: require("@/contract/communication-contract.vue").default,
        },
        {
          path: "trendyolinfo",
          component: require("@/contract/trendyolinfo.vue").default,
        },
      ],
    },
    {
      path: "/v",
      component: visitorLayout,
      meta: { disableControlAuth: true },
      children: [
        {
          path: ":a",
          component: require("@/visitor/collection-list.vue").default,
        },
        {
          path: ":a/:b",
          component: require("@/visitor/collection.vue").default,
        },
      ],
    },
    {
      path: "/admin",
      component: adminLayout,
      meta: { requiresAuth: true },
      children: [
        {
          path: "home",
          component: require("@/admin/views/home/home.vue").default,
        },
        {
          path: "info",
          component: require("@/admin/views/info/info.vue").default,
        },
        {
          path: "faq",
          component: require("@/admin/views/faq/faq.vue").default,
        },
        {
          path: "user-list",
          component: require("@/admin/views/users/user-list.vue").default,
        },
        {
          path: "role-list",
          component: require("@/admin/views/roles/role-list.vue").default,
        },
        {
          path: "storage-list",
          component: require("@/admin/views/storage/storage-list.vue").default,
        },
        {
          path: "company-list",
          component: require("@/admin/views/companies/company-list.vue")
            .default,
        },
        {
          path: "discount-campaign-list",
          component: require("@/admin/views/discount-campaigns/discount-campaign-list.vue")
            .default,
        },
        {
          path: "commission-list",
          component: require("@/admin/views/commissions/commission-list.vue")
            .default,
        },
        {
          path: "payment-tracking-list",
          component: require("@/admin/views/paymenttrackings/payment-tracking-list.vue")
            .default,
        },
        {
          path: "ticket-list",
          component: require("@/admin/views/tickets/ticket-list.vue").default,
        },
        {
          path: "trendyol-info",
          component: require("@/admin/views/trendyolinfo/trendyolinfo.vue")
            .default,
        },
        {
          path: "fantaslink-info",
          component: require("@/admin/views/fantaslinkinfo/fantaslinkinfo.vue")
            .default,
        },
        // {
        //   path: "link-creator",
        //   component: require("@/admin/views/linkcreator/linkcreator.vue")
        //     .default,
        // },
        {
          path: "profile",
          component: require("@/admin/views/profile/profile.vue").default,
        },
        {
          path: "trendyol-commission-list",
          component: require("@/admin/views/trendyolcommissions/trendyol-commission-list.vue")
            .default,
        },

        {
          path: "campaign",
          component: require("@/admin/views/campaign/campaign.vue").default,
        },
        {
          path: "company-user",
          component: require("@/admin/views/companyusers/company-user-list.vue")
            .default,
        },
        {
          path: "legislation",
          component: require("@/admin/views/legislation/legislation.vue")
            .default,
        },
        {
          path: "coupon",
          component: require("@/admin/views/coupon/coupon.vue").default,
        },
        {
          path: "collection-list",
          component: require("@/admin/views/collections/collection-list.vue")
            .default,
        },
      ],
    },
  ],
});

router.beforeEach((to: any, from: any, next: any) => {
  if (to.matched.some((record: any) => record.meta.disableControlAuth)) {
    next();
  } else if (to.matched.some((record: any) => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!AuthStore.isSignedIn()) {
      next({
        path: "/account/login",
        query: { redirect: to.fullPath },
      });
    }
  } else if (to.matched.some((record: any) => !record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (AuthStore.isSignedIn()) {
      next({
        path: "/admin/home",
        query: { redirect: to.fullPath },
      });
    }
  }

  next(); // make sure to always call next()!
});

export default router;
